<template>
  <div id="login">
    <div class="login-box">
      <div class="left">
        <div class="logo-line-box">
          <img class="logo" :src="$toUrl('edp-login-logo.png')" alt="">
        </div>
        <div class="swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img :src="$toUrl('edp-login-swiper1.png')" alt="">
            </div>
            <div class="swiper-slide">
              <img :src="$toUrl('edp-login-swiper2.png')" alt="">
            </div>
            <div class="swiper-slide">
              <img :src="$toUrl('edp-login-swiper3.png')" alt="">
            </div>
            <div class="swiper-slide">
              <img :src="$toUrl('edp-login-swiper4.png')" alt="">
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div class="right">
        <div class="title">欢迎登录</div>
        <form class="form">
          <div class="form-item">
            <div class="label">账号</div>
            <input name="phone" type="text" v-model="query.username">
          </div>
          <div class="form-item">
            <div class="label">密码</div>
            <input name="password" type="password" v-model="password_input">
          </div>
        </form>
        <div class="action">
          <div class="login" @click="login">登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetch } from '@/utils/request'
import appsettings from '@/utils/appsettings'
import { JSEncrypt } from 'jsencrypt'
import Swiper from "swiper";
import {Autoplay, Pagination} from "swiper/modules";

export default {
  name: 'login',
  data() {
    return {
      appsettings: appsettings,
      query: {
        username: '',
        password: '',
      },
      password_input: '',
      public_key: ''
    }
  },
  created() {
    this.getMyconfig('global_config', 'public_key')
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        new Swiper('.swiper', {
          modules: [Pagination, Autoplay],
          autoplay: {
            delay: 3000,
          },
          pagination: {
            el: '.swiper-pagination',
          }
        })
      }, 500)
    })
  },
  methods: {
    getMyconfig(type, code) {
      let url = '/cpy/cat/retrieveportal/global/pub'
      fetch('post', url, {
        cat_type: type,
        cat_code: code
      }).then((res) => {
        if (res && res.status === 0) {
          if (res.data.datas.length > 0) {
            this.public_key = res.data.datas[0].field_value
          }

        } else {
          this.$message.error(res.message)
        }
      })
    },
    login() {
      let that = this
      if (this.query.username === '') {
        this.$message.error('请输入用户名！')
        return
      }
      if (this.password_input === '') {
        this.$message.error('请输入密码！')
        return
      }
      console.log(this.query);
      let encryptor = new JSEncrypt() // 新建JSEncrypt对象
      encryptor.setPublicKey(this.public_key) // 设置公钥
      let rsaPassWord = encryptor.encrypt(this.password_input) // 对密码进行加密
      this.query.password = rsaPassWord

      var query = JSON.parse(JSON.stringify(this.query))
      console.log(this.query);

      delete query.password //避免密码明文传输

      fetch('post', '/account/login', this.query).then((res) => {
        if (res && res.status === 0) {
          localStorage.setItem('token', res.data.token)
          sessionStorage.removeItem("cat")
          this.storage.setItem({ name: 'company_info', value: res.data })
          this.$message.success('登录成功')
          if (res.data.url !== '') {
            that.$router.push(res.data.url)
            that.getallowedTypes()
          } else {
            that.$router.push('/workspace/dashboard')
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getallowedTypes() {
      fetch('post', '/attachment/allowed_types', {}).then((res) => {
        if (res && res.status === 0) {
          console.log(res.data);
          this.storage.setItem({ name: 'allowed_types', value: res.data })
        }
      })
    }
  }

}
</script>

<style scoped lang="less" >
#login {
  background: #F8F7FC;
  height: 100vh;
  min-height: 500px;
  padding: 10px 11px;
  .login-box {
    background: white;
    border-radius: 38px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: stretch;
    >.left {
      border-radius: 38px 0 0 38px;
      width: 50%;
      background: #4a8ffc;
      .logo-line-box {
        padding-left: 61px;
        padding-top: 52px;
        .logo {
          width: 170px;
          height: 66px;
        }
      }
      .swiper {
        height: 75%;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .swiper-wrapper {
          height: calc(100% - 33px);
          .swiper-slide {
            width: 100%;
            text-align: center;
            img {
              height: 100%;
            }
          }
        }
        .swiper-pagination {
          flex-shrink: 0;
          height: 20px;
          margin-top: 13px;
          text-align: center;
          display: block;
          /deep/ .swiper-pagination-bullet {
            display: inline-block;
            width: 12px;
            height: 12px;
            background: white;
            border-radius: 50%;
            opacity: .4;
            &:not(:last-child) {
              margin-right: 10px;
            }
            &-active {
              opacity: 1;
            }
          }
        }
      }
    }
    >.right {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 50%;
      >.title {
        margin-top: -35px;
        font-size: 30px;
        font-weight: 500;
        color: #000;
        margin-bottom: 50px;
      }
      .form {
        max-width: 568px;
        width: 80%;
        margin-bottom: 120px;
        .form-item {
          &:not(:last-child) {
            margin-bottom: 44px;
          }
          .label {
            font-size: 22px;
            font-weight: 500;
            color: #000;
            padding-left: 12px;
            line-height: 1;
          }
          input {
            margin-top: 18px;
            width: 100%;
            height: 70px;
            box-shadow: 0 2px 20px 0 #d8d8d880;
            border: none;
            outline: none;
            border-radius: 8px;
            font-size: 16px;
            padding-left: 20px;
          }
        }
      }
      .action {
        .login {
          width: 248px;
          height: 64px;
          background: #4C8DFE;
          border-radius: 13px;
          text-align: center;
          line-height: 64px;
          font-size: 22px;
          font-weight: 500;
          color: white;
        }
      }
    }
  }
}
</style>
